import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

import Layout from '../components/layout/Layout.en'
import Seo from '../components/Seo'
import Slide from '../components/slide/Slide'
import SlideItem from '../components/slide/SlideItem'

import BenefitSmartHelp from '../components/benefit-smart-help/BenefitSmartHelp'
import CovidRespond from '../components/covid-respond/CovidRespond'
import Statistic from '../components/statistic/Statistic'
import StatisticItem from '../components/statistic/StatisticItem'
import CovidRespondItem from '../components/covid-respond/CovidRespondItem'
import EconomyRecovery from '../components/economy-recovery/EconomyRecovery'
import EconomyRecoveryItem from '../components/economy-recovery/EconomyRecoveryItem'
import BusinessAssistance from '../components/business-assistance/BusinessAssistance'
import BusinessAssistanceItem from '../components/business-assistance/BusinessAssistanceItem'
import Testimonial from '../components/testimonial/Testimonial'
import TestimonialItem from '../components/testimonial/TestimonialItem'
import MediaHelpPage from '../components/media/MediaHelpPage'
import BenefitSmartItem from '../components/benefit-smart-help/BenefitSmartItem'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const BantuanPintarPage = ({ data, location }) => {
	const { siteUrl } = useSiteMetadata()

	const sliderData = data.wpgHeadlessQuery.fieldHelpPage.slideBanner
	const economyRecoveryData = data.wpgHeadlessQuery.fieldHelpPage.economyRecoveryItem
	const businessAssistanceData = data.wpgHeadlessQuery.fieldHelpPage.businessAssistance
	const testimonialData = data.wpgHeadlessQuery.fieldHelpPage.testimoni
	const seoData = data.wpgHeadlessQuery.seo

	return (
		<Layout location={location} logo={data.logoImage}>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{
						property: 'og:url',
						content: `${siteUrl}${location.pathname}`,
					},
					{ property: 'og:title', content: seoData.opengraphTitle },
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content: seoData.opengraphImage !== null ? seoData.opengraphImage.localFile.publicURL : thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content: seoData.twitterImage !== null ? seoData.twitterImage.localFile.publicURL : thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<section className='hero-section'>
				<Slide>
					{sliderData.map((itemData, index) => {
						return <SlideItem key={index} withLogo={itemData.withLogo} title={itemData.title} content={itemData.content} buttonLabel={itemData.buttonLabel} buttonLink={itemData.buttonLink} logoSrc={getImage(itemData.logoSrc.localFile)} imageSrc={getImage(itemData.imageSrc.localFile)} imageXsSrc={getImage(itemData.imageXsSrc.localFile)} withButton={itemData.withButton} hideLogoMobile={true} />
					})}
				</Slide>
			</section>

			<section className='benefit-smart-assist-section'>
				<BenefitSmartHelp title='Bantuan Pintar Advantage'>
					<BenefitSmartItem
						title='Quick <br /><br />'
						content='Aid stock are distributed in three days'
						imageSrc={{
							image: getImage(data.benefitImages.nodes[0]),
							class: `smart-benefit-image-01`,
						}}
						imageAlt='wpg-illustration'
					/>
					<BenefitSmartItem
						title='Monitored <br /><br />'
						content='All processes are monitored from the ordering process to the distribution process '
						imageSrc={{
							image: getImage(data.benefitImages.nodes[1]),
							class: `smart-benefit-image-02`,
						}}
						imageAlt='wpg-illustration'
					/>
					<BenefitSmartItem
						title='Equal and Targetted '
						content="The beneficiaries' location and identification are according to RT/RW validation "
						imageSrc={{
							image: getImage(data.benefitImages.nodes[2]),
							class: `smart-benefit-image-03`,
						}}
						imageAlt='wpg-illustration'
					/>
					<BenefitSmartItem
						title='100% Fully Procured'
						content='Warung Pintar provide a system for the aid distribution'
						imageSrc={{
							image: getImage(data.benefitImages.nodes[3]),
							class: `smart-benefit-image-04`,
						}}
						imageAlt='wpg-illustration'
					/>
					<BenefitSmartItem
						title='Rp 0 for distribution'
						content='The aid is distributed<br> as per our business as usual '
						imageSrc={{
							image: getImage(data.benefitImages.nodes[4]),
							class: `smart-benefit-image-05`,
						}}
						imageAlt='wpg-illustration'
					/>
					<BenefitSmartItem
						title='Obey Health Protocol'
						content='Warung owner deliver the aid directly to the beneficiaries house or they can take it from Warung'
						imageSrc={{
							image: getImage(data.benefitImages.nodes[5]),
							class: `smart-benefit-image-06`,
						}}
						imageAlt='wpg-illustration'
					/>
				</BenefitSmartHelp>
			</section>

			<section className='smart-assist-achievement-section'>
				<Statistic title='Bantuan Pintar Achievement' isTitleYellow={true} isHelpPage={true}>
					<div className='columns is-multiline'>
						<div className='column is-12-desktop is-12-widescreen is-12-tablet'>
							<StatisticItem
								title='Rp 20 Billion'
								subtitle='Aids distributed in form of groceries,<br/> instant foods, health protocol kit, and economic<br/> empowerment'
								subtitleMobile='Aids distributed in form of groceries, instant foods,<br/> health protocol kit, and economic<br/> empowerment'
								imageSrc={{
									image: getImage(data.achievementImages.nodes[0]),
									class: 'statistic-image-01',
								}}
								description={true}
								isHelpPage={true}
								imageAlt='wpg-illustration'
							/>
						</div>
						<div className='column is-12-desktop is-8-widescreen is-12-tablet'>
							<StatisticItem
								title='250,000+'
								subtitle='affected people'
								subtitleMobile='affected people'
								isHelpPage={true}
								imageSrc={{
									image: getImage(data.achievementImages.nodes[1]),
									class: 'statistic-image-02',
								}}
								imageAlt='wpg-illustration'
							/>
						</div>
						<div className='column is-12-desktop is-4-widescreen is-12-tablet is-hidden-mobile'>
							<StatisticItem
								title='20+'
								subtitle='Collaborator'
								subtitleMobile='Collaborator'
								isHelpPage={true}
								imageSrc={{
									image: getImage(data.achievementImages.nodes[2]),
									class: 'statistic-image-03',
								}}
								imageAlt='wpg-illustration'
							/>
						</div>
						<div className='column is-4-widescreen is-12-desktop is-12-tablet is-hidden-tablet'>
							<StatisticItem
								title='20+'
								subtitle='Collaborator'
								subtitleMobile='Collaborator'
								isHelpPage={true}
								imageSrc={{
									image: getImage(data.achievementImages.nodes[5]),
									class: 'statistic-image-03',
								}}
								imageAlt='wpg-illustration'
							/>
						</div>
						<div className='column is-12-desktop is-6-widescreen is-12-tablet'>
							<StatisticItem
								title='20+'
								subtitle='Cities and Regencies'
								subtitleMobile='Cities and Regencies'
								isHelpPage={true}
								imageSrc={{
									image: getImage(data.achievementImages.nodes[3]),
									class: 'statistic-image-04',
								}}
								imageAlt='wpg-illustration'
							/>
						</div>
						<div className='column is-12-desktop is-6-widescreen is-12-tablet'>
							<StatisticItem
								title='55'
								subtitle='Programmes <br/> and Initiatives'
								subtitleMobile='Programmes and Initiatives'
								isHelpPage={true}
								imageSrc={{
									image: getImage(data.achievementImages.nodes[4]),
									class: 'statistic-image-05',
								}}
								imageAlt='wpg-illustration'
							/>
						</div>
					</div>
				</Statistic>
			</section>

			<section className='covid-respond-section'>
				<div className='is-hidden-desktop'>
					<CovidRespond title='Pandemic Response Initiatives ' subtitle='Aid distribution in form of groceries, instant food packages, and health protocol kits directly to teh beneficiaries.'>
						<CovidRespondItem
							title='Rp 2,3 Billion +'
							subtitle='Pandemic Response Aid'
							imageSrc={{
								image: getImage(data.covidrespondImages.nodes[0]),
								class: 'covid-image-01',
							}}
							imageAlt='wpg-illustration'
						/>
						<CovidRespondItem
							title='30,000'
							subtitle='Health protocol kits <br />(mask and handsanitizer)'
							imageSrc={{
								image: getImage(data.covidrespondImages.nodes[1]),
								class: 'covid-image-01',
							}}
							imageAlt='wpg-illustration'
						/>
						<CovidRespondItem
							title='30,000'
							subtitle='groceries and instant foods'
							imageSrc={{
								image: getImage(data.covidrespondImages.nodes[2]),
								class: 'covid-image-01',
							}}
							imageAlt='wpg-illustration'
						/>
					</CovidRespond>
				</div>

				<div className='is-hidden-touch'>
					<CovidRespond title='Pandemic Response Initiatives ' subtitle='Aid distribution in form of groceries, instant food packages, and health protocol kits directly to teh beneficiaries.'>
						<div className='column is-8'>
							<CovidRespondItem
								title='Rp 2,3 Billion +'
								subtitle='Pandemic Response Aid'
								imageSrc={{
									image: getImage(data.covidrespondImages.nodes[0]),
									class: 'covid-image-01',
								}}
								imageAlt='wpg-illustration'
							/>
						</div>
						<div className='column is-4'>
							<div
								style={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
								}}
							>
								<CovidRespondItem
									title='30,000'
									subtitle='Health protocol kits <br />(mask and handsanitizer)'
									imageSrc={{
										image: getImage(data.covidrespondImages.nodes[1]),
										class: 'covid-image-02',
									}}
									imageAlt='wpg-illustration'
								/>
								<CovidRespondItem
									title='30,000'
									subtitle='groceries and instant foods'
									imageSrc={{
										image: getImage(data.covidrespondImages.nodes[2]),
										class: 'covid-image-02',
									}}
									imageAlt='wpg-illustration'
								/>
							</div>
						</div>
					</CovidRespond>
				</div>

				<div className='apl-distribution'>
					<div className='container'>
						<h2>Distribution Channel of Pandemic Response Aid</h2>
						<div className='apl-distribution-contents'>
							<GatsbyImage className='is-hidden-mobile' image={getImage(data.distributionFlow.nodes[9])} alt='wpg-illustrator' />
							<GatsbyImage className='is-hidden-tablet' image={getImage(data.distributionFlow.nodes[10])} alt='wpg-illustrator' />
						</div>
					</div>
				</div>
			</section>

			<section className='economy-recovery-section'>
				<EconomyRecovery title='Economic Recovery Initiative' subtitle='Covid-19 Pandemic caused at least 500 thousand people lose their jobs and 47% of MSMEs experienced a lack of business capital which 70% are in trade sector'>
					{economyRecoveryData.map((itemData, index) => (
						<EconomyRecoveryItem key={index} title={itemData.title} imageSrc={getImage(itemData.imageSrc.localFile)} description={itemData.description} />
					))}
				</EconomyRecovery>
			</section>

			<section className='business-assistance-section'>
				<BusinessAssistance title='Online and offline business assistance' subtitle='In addition to business capital, Warung Pintar also provides online and offline business assistance'>
					{businessAssistanceData.map((itemData, index) => (
						<BusinessAssistanceItem key={index} content={itemData.content} imageSrc1={getImage(itemData.imageSrc1.localFile)} imageSrc2={getImage(itemData.imageSrc2.localFile)} imageAlt1={itemData.imageAlt1} imageAlt2={itemData.imageAlt2} />
					))}
				</BusinessAssistance>
			</section>

			<section className='testimoni-kolaborator'>
				<Testimonial title='Collaborators Testimoni' isInverse={true} helpPage={true}>
					{testimonialData.map((itemData, index) => (
						<TestimonialItem key={index} content={itemData.content} name={itemData.name} description={itemData.description} iconSrc={getImage(data.grosirPintarIcon)} imageSrc={getImage(itemData.imageSrc.localFile)} imageAlt={itemData.imageAlt} isGrosirPintar={itemData.isGrosirPintar} isHelpPage={true} />
					))}
				</Testimonial>

				<MediaHelpPage imageSrc={getImage(data.mediaImage.nodes[0])} images={data.mediaImages} />
			</section>

			<section className='jumbotron-section'>
				<div className='apl-jumbotron-help-page'>
					<div className='columns container is-gapless'>
						<div className='column is-5 content'>
							<h2>Let's Collaborate!</h2>
							<p>We're open for any kind of partnership for aid distribution and impactful, transparent and targetted economic empowerment</p>
							<a href='mailto:partnership@warungpintar.co' className='apl-jumbotron-help-page--button--primary' target='_blank' rel='nofollow noopener noreferrer'>
								Contact Us
							</a>
						</div>
						<div className='column is-7' style={{ marginTop: 'auto' }}>
							<GatsbyImage className='jumbotron-image' alt='wpg-illustrator' image={getImage(data.jumbotronImage)} />
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "bantuan-pintar-en" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			fieldHelpPage {
				slideBanner {
					buttonLabel
					buttonLink
					withButton
					withLogo
					content
					title
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1000)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1000)
							}
						}
					}
					logoSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 600)
							}
						}
					}
				}
				economyRecoveryItem {
					description
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1000)
							}
						}
					}
					title
				}
				testimoni {
					content
					description
					imageAlt
					name
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
				}
				businessAssistance {
					content
					imageAlt1
					imageAlt2
					imageSrc1 {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageSrc2 {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
				}
			}
		}

		benefitImages: allFile(filter: { relativeDirectory: { eq: "illustrations/smart-help-benefits" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		achievementImages: allFile(filter: { relativeDirectory: { eq: "banners/smart-achievements" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		covidrespondImages: allFile(filter: { relativeDirectory: { eq: "banners/respond-to-covid" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		distributionFlow: allFile(filter: { relativeDirectory: { eq: "illustrations/distribution-flow" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1200)
				}
			}
		}
		mediaImages: allFile(filter: { relativeDirectory: { eq: "medias/help-page-medias" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 200, transformOptions: { grayscale: true })
				}
			}
		}
		jumbotronImage: file(relativePath: { eq: "apps/jumbotron-help-page/jumbotron-help-page.png" }) {
			childImageSharp {
				gatsbyImageData(width: 1200)
			}
		}
		mediaImage: allFile(filter: { relativeDirectory: { eq: "medias/help-page-medias" } }, sort: { order: ASC, fields: [name] }) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1200)
				}
			}
		}
		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
	}
`

export default BantuanPintarPage
